const wordWrapContainer = document.querySelectorAll('.js-word-wrap');

wordWrapContainer[0] && wordWrapContainer.forEach(wrapContainer => {
    const innerNodes = Array.from(wrapContainer.children);
    const childNodesWidth = innerNodes.reduce((acumulator, node) => {
        return acumulator + node.offsetWidth;
    }, 0);

    if (wrapContainer.offsetWidth < childNodesWidth) {
        wrapContainer.style['display'] = "flex";
        wrapContainer.style['flex-direction'] = "column-reverse";
        wrapContainer.style['align-items'] = "flex-start";
        innerNodes[0].style['margin-top'] = "1rem";
    }
})