// const dropDownList = document.querySelectorAll('.js-dropdown-list');

// dropDownList[0] && dropDownList.forEach(list => {
//     const dropDownButton = list.querySelector('.js-dropdown-list-button');

//     const handleClick = (evt) => {
//         if (!evt.target.closest('.dropdown-menu')) {
//             closeMenu();
//         }
//     }

//     const handleEscPress = (evt) => {
//         if (evt.key === 'Escape') {
//             closeMenu();
//         }
//     }

//     const closeMenu = () => {
//         list.classList.remove('dropdown-list--active');
//         window.removeEventListener('click', handleClick);
//         window.removeEventListener('keydown', handleEscPress);
//     }

//     const openMenu = (evt) => {
//         evt.preventDefault();
//         if (!list.classList.contains('dropdown-list--active')) {
//             list.classList.add('dropdown-list--active');

//             setTimeout(() => {
//                 window.addEventListener('click', handleClick);
//                 window.addEventListener('keydown', handleEscPress);
//             }, 10);
//         }
//     }


//     dropDownButton.addEventListener('click', openMenu);
// });


const dropDownList = document.querySelector('.js-dropdown-list');

if (dropDownList) {
    const choices = new Choices(dropDownList, {
        searchEnabled: false,
        itemSelectText: '',
        shouldSort: false,
    });
}
