const searchModal = document.querySelector('.search');

if (searchModal) {
    const searchButton = document.querySelector('.js-search-button');

    const initSearch = () => {
        const searchInput = searchModal.querySelector('.js-search-input');
        const searchIcon = searchModal.querySelector('.js-search__img');
        const closeButton = searchModal.querySelector('.js-modal-close ');

        const clearSearchButton = searchModal.querySelector('.js-search__clear');

        setTimeout(()=> {
            searchInput.focus();
        }, 200);

        // работа с событием ввода в инпут
        const inputWork = () => {
            if (searchInput.value.length > 0) {
                clearSearchButton.classList.add('search__img--active');
                searchIcon.classList.remove('search__img--active');
            } else {
                clearSearchButton.classList.remove('search__img--active');
                searchIcon.classList.add('search__img--active');
            }
        }

        const clearInput = () => {
            searchInput.value = '';
            searchInput.focus();
            clearSearchButton.classList.remove('search__img--active');
            searchIcon.classList.add('search__img--active');
        }

        const removeSearch = () => {
            searchInput.removeEventListener('input', inputWork);
            clearSearchButton.removeEventListener('click', clearInput);
            closeButton.removeEventListener('click', removeSearch);
            window.removeEventListener('keydown', handleEscPress);
            searchInput.value = '';
        }

        const handleEscPress = (evt) => {
            if (evt.key === 'Escape') {
                removeSearch();
            }
        };

        searchInput.addEventListener('input', inputWork);
        clearSearchButton.addEventListener('click', clearInput);

        // удаляет обработчики при закрытии модакли поиска
        closeButton.addEventListener('click', removeSearch);
        window.addEventListener('keydown', handleEscPress);
    }

    function get_result(isSubmit = false, selected = '', isClear = false) {
        const lang = $('html').attr('lang') === 'ru' ? '' : "/" + $('html').attr('lang');
        $('#search_result').html('');
        const moreBtnContainer = $('.js-search-paginator_custom');
        let langPhrase__more = 'Загрузить еще';
        switch (document.documentElement.lang) {
            case 'en':
                langPhrase__more = 'More';
                break;
        }
        if (isClear) q = '';
        $.ajax({
            type: "POST",
            url: lang + "/search/ajax_search.php",
            data: "q=" + q + "&clear_post=y&section=" + selected,
            dataType: 'json',
            success: function (json) {
                const moreBtn = $('.js-search__show-more-custom');
                $('#search_result').append('<div class="live-search"></div>');
                $('.js-search__text').html(json.COUNT);
                if (json.NAV_STRING !== undefined)
                    $('.js-search-paginator').html(json.NAV_STRING);
                $('.js-search__results').html('');
                $.each(json.ITEMS, function (index, element) {
                    var body = element.BODY !== undefined ? element.BODY : '';
                    let hiddenClass = index > 5 ? 'search__results-item-hidden' : '';
                    $('.js-search__results').append(
                        '<li class="search__results-item js-search__results-item '+ hiddenClass +'">' +
                        '<a target="_blank" class="search__results-link" href="' + element.URL + '">' +
                        '<p class="search__results-title">' + element.TITLE + '</p>' +
                        '<p class="search__results-text">' + element.BODY_FORMATED +
                        '</p></a></li>'
                    );
                });
                if (json.ITEMS === undefined) {
                    moreBtn.css('display', 'none');
                } else if (json.ITEMS.length > 5) {
                    moreBtn.css('display', 'block');
                }

                moreBtn.off();
                moreBtn.on('click', function (e) {
                    e.preventDefault();
                    $('.search__results-item-hidden').each(function (index, el) {
                        if (index < 6) {
                            $(el).removeClass('search__results-item-hidden');
                            if (index <= $('.search__results-item-hidden').length) {
                                moreBtn.toggle();
                            }
                        }
                    })
                });
            }
        });
    }

    var timer = 0;
    var q = '';
    $(document).ready(function () {
        const searchInput = $('.js-search-input');
        const selectedElement = $('.js-section-selector-search');

        searchInput.keyup(function (e) {
            if(e.keyCode === 13) return false;
            q = this.value;
            clearTimeout(timer);
            timer = setTimeout(get_result(false, selectedElement.val()), 500);
        });

        searchInput.keydown(function(e) {
            if(e.keyCode === 13) {
                e.preventDefault();
            }
        });

        $('.js-search__clear').on('click', function (e) {
            get_result(true, selectedElement.val(), true);
        });

        selectedElement.change(function (e) {
            get_result(true, $(this).val());
        });
    });

    searchButton.addEventListener('click', initSearch);
}
