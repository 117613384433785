const expertiseBtn = document.querySelector('.main-expertise__btn')
const mainCompetencies = document.querySelector('.main-competencies__btn')
const playBtn = document.querySelector('.modal__play-video-btn')
const imgVideo = document.querySelector('.modal__img-video-banner')
const videoWrapper = document.querySelector('.modal__video-main-wrapper')
const modal = document.querySelector('.modal--video-main')
const iframe = document.querySelector('.modal__iframe-main')

playBtn && playBtn.addEventListener('click', () => {
    playBtn.remove()
    imgVideo.remove()
    iframe.classList.add('active')
    iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
})


mainCompetencies && mainCompetencies.addEventListener('click', () => {
    mainCompetencies.classList.contains('active') ?  mainCompetencies.textContent = 'Все решения' : mainCompetencies.textContent = 'Скрыть'
    document.querySelector('.main-competencies__cards').classList.toggle('active')
    mainCompetencies.classList.toggle('active')
        
})