//=require scripts/helpers.js
//=require scripts/config.js
//=require scripts/scripts.js
//=require scripts/header-nav.js
//=require scripts/lang-toggle.js
//=require scripts/burger.js
//=require scripts/sliders.js
//=require scripts/get-quiz-data.js
//=require scripts/quiz.js
//=require scripts/map.js
//=require scripts/validation.js
//=require scripts/modals.js
//=require scripts/video-modal.js
//=require scripts/contact-map.js
//=require scripts/tabs.js
//=require scripts/search.js
//=require scripts/dropdown-list.js
//=require scripts/filter.js
//=require scripts/show-more.js
//=require scripts/word-wrap.js
//=require scripts/polls.js
//=require scripts/main.js
//=require scripts/accordions.js
//=require scripts/service-card.js