document.addEventListener("DOMContentLoaded", () => {
    //main-slider-en
    if (document.querySelector(".js-main-slider-en")) {
        const buttons = document.querySelector(
            ".main__slider-en .slider__buttons"
        );

        if (buttons) {
            // function checkWidthSlider() {
            const wrapper = document.querySelector(
                ".js-main-slider-en .swiper-wrapper"
            );

            if (wrapper.scrollWidth <= wrapper.clientWidth) {
                buttons.style.opacity = "0";
            } else {
                buttons.style.opacity = "1";
            }
            // }

            // checkWidthSlider();
            // window.addEventListener('resize', checkWidthSlider)
        }

        function setHeightPartners(swiper) {
            const partners =
                swiper.wrapperEl.querySelectorAll(".slider__partners");

            let maxHeightPartners = 0;

            partners.forEach((item) => {
                const itemHeight = item.getBoundingClientRect().height;

                if (itemHeight > maxHeightPartners) {
                    maxHeightPartners = itemHeight;
                }
            });

            partners.forEach((item) => {
                item.style.minHeight = maxHeightPartners + "px";
            });
        }

        const sliderConfig = new Swiper(".js-main-slider-en", {
            slidesPerView: 1,
            loop: false,
            navigation: {
                nextEl: ".main__slider-en .slider__buttons .slider__button--next",
                prevEl: ".main__slider-en .slider__buttons .slider__button--prev",
            },
            on: {
                init: function (swiper) {
                    setHeightPartners(swiper);
                },
                resize: function (swiper) {
                    setHeightPartners(swiper);
                },
            },
            breakpoints: {
                650: {
                    slidesPerView: 2,
                },
                1000: {
                    slidesPerView: 3,
                },
                1900: {
                    slidesPerView: 4,
                },
            },
        });
    }

    // buttons
    const prevButtongray = `<button class="slider__button slider__button--gray slider__button--prev" type="button">
        <svg class="slider__button-svg" width="32" height="16">
            <use xlink:href="#slider-arrow"></use>
        </svg>
    </button>`;

    const nextButtongray = `<button class="slider__button slider__button--gray slider__button--next" type="button">
        <svg class="slider__button-svg" width="32" height="16">
            <use xlink:href="#slider-arrow"></use>
        </svg>
    </button>`;

    // -------------- main-slider ---------------
    if (document.querySelector(".js-main-slider")) {
        const buttons = document.querySelector(
            ".main__slider .slider__buttons"
        );

        if (buttons) {
            // function checkWidthSlider() {
            const wrapper = document.querySelector(
                ".js-main-slider .swiper-wrapper"
            );

            if (wrapper.scrollWidth <= wrapper.clientWidth) {
                buttons.style.opacity = "0";
            } else {
                buttons.style.opacity = "1";
            }
            // }

            // checkWidthSlider();
            // window.addEventListener('resize', checkWidthSlider)
        }

        function setHeightPartners(swiper) {
            const partners =
                swiper.wrapperEl.querySelectorAll(".slider__partners");

            let maxHeightPartners = 0;

            partners.forEach((item) => {
                const itemHeight = item.getBoundingClientRect().height;

                if (itemHeight > maxHeightPartners) {
                    maxHeightPartners = itemHeight;
                }
            });

            partners.forEach((item) => {
                item.style.minHeight = maxHeightPartners + "px";
            });
        }

        const sliderConfig = new Swiper(".js-main-slider", {
            slidesPerView: 1,
            loop: false,
            navigation: {
                nextEl: ".main__slider .slider__buttons .slider__button--next",
                prevEl: ".main__slider .slider__buttons .slider__button--prev",
            },
            on: {
                init: function (swiper) {
                    setHeightPartners(swiper);
                },
                resize: function (swiper) {
                    setHeightPartners(swiper);
                },
            },
            breakpoints: {
                650: {
                    slidesPerView: 2,
                },
                1000: {
                    slidesPerView: 3,
                },
                1900: {
                    slidesPerView: 4,
                },
            },
        });
    }

    // ----------------- case-slider -------------------
    $(".js-case-slider").slick({
        lazyLoad: "progressive",
        infinite: false,
        slidesToShow: 3,
        variableWidth: true,
        appendArrows: $(".js-case-slider-buttons"),
        prevArrow: prevButtongray,
        nextArrow: nextButtongray,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    variableWidth: false,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1.5,
                    variableWidth: false,
                }
            },
                    {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    variableWidth: true,
                }
            }
        ],
    });

    // ----------------- awards-slider -------------------
    const slidesCount = document.querySelectorAll(
        ".js-awards-slider .slider__slide"
    );

    $(".js-awards-slider").slick({
        lazyLoad: "progressive",
        infinite: false,
        slidesToShow: 6,
        appendArrows: $(".js-awards-slider-buttons"),
        prevArrow: prevButtongray,
        nextArrow: nextButtongray,
        initialSlide: slidesCount.length > 6 ? slidesCount.length - 6 : false,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 5,
                    initialSlide:
                        slidesCount.length > 5 ? slidesCount.length - 5 : false,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    initialSlide:
                        slidesCount.length > 4 ? slidesCount.length - 4 : false,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    initialSlide:
                        slidesCount.length > 3 ? slidesCount.length - 3 : false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    variableWidth: true,
                    slidesToShow: 1,
                    initialSlide: slidesCount.length,
                },
            },
        ],
    });
    $(".js-history-slider").slick({
        lazyLoad: "progressive",
        infinite: false,
        slidesToShow: 6,
        appendArrows: $(".js-awards-slider-buttons"),
        prevArrow: prevButtongray,
        nextArrow: nextButtongray,
        initialSlide: slidesCount.length > 6 ? slidesCount.length - 6 : false,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 5,
                    // initialSlide: slidesCount.length > 5 ? slidesCount.length - 5 : false,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    initialSlide:
                        slidesCount.length > 4 ? slidesCount.length - 4 : false,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    initialSlide:
                        slidesCount.length > 3 ? slidesCount.length - 3 : false,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    initialSlide:
                        slidesCount.length > 2 ? slidesCount.length - 2 : false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    variableWidth: true,
                    slidesToShow: 1,
                    initialSlide: slidesCount.length,
                },
            },
        ],
    });
    // ----------------- trends-slider-main-page -------------------
    $(".js-trends-slider-main").each(function () {
        if (window.innerWidth > 1366) {
            const slides = this.querySelectorAll(".slider__slide");

            for (let i = 0; i < slides.length; i++) {
                if (i % 3 === 0) {
                    const nextOne = slides[i + 1],
                        nextTwo = slides[i + 2];

                    if (nextOne) {
                        nextOne.classList.add("trends__item-grid");
                        slides[i].append(nextOne);
                    }

                    if (nextTwo) {
                        nextTwo.classList.add("trends__item-grid");
                        slides[i].append(nextTwo);
                    }
                }
            }
        }

        $(this).slick({
            lazyLoad: "progressive",
            infinite: false,
            slidesToShow: 1,
            appendArrows: $(".js-trends-slider-buttons"),
            prevArrow: prevButtongray,
            nextArrow: nextButtongray,
            adaptiveHeight: true,
        });
    });

    //-------------------- clients-slider --------------------------
    $(".js-clients-slider").slick({
        lazyLoad: "progressive",
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 2,
        appendArrows: $(".js-clients-slider-buttons"),
        prevArrow: prevButtongray,
        nextArrow: nextButtongray,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    });

    //------------------ similar-slider business-case-details-page------------------
    $(".js-similar-slider").slick({
        lazyLoad: "progressive",
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        appendArrows: $(".js-similar-slider-buttons"),
        prevArrow: prevButtongray,
        nextArrow: nextButtongray,
        responsive: [
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });

    //------------------ similar-slider solution-details-page------------------
    $(".js-similar-slider-solutions").slick({
        lazyLoad: "progressive",
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        appendArrows: $(".js-similar-slider-solutions-buttons"),
        prevArrow: prevButtongray,
        nextArrow: nextButtongray,
        responsive: [
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });

    //------------------ video-slider solution-details-page------------------
    const videoSlider = $(".js-solutions-video-slider");

    if (videoSlider[0]) {
        const isMobile = () => {
            if (document.body.clientWidth > 768) {
                return ".js-solutions-video-slider-preview";
            }
            return false;
        };

        const slides = document.querySelectorAll(
            ".js-solutions-video-slider .slider__slide"
        );

        slides.forEach((slide, i) => {
            slide.setAttribute("data-id", i + 1);
        });

        const counterCurrent = document.querySelector(
            ".solution-details__video-slider .js-slider-counter-current"
        );
        const counterAll = document.querySelector(
            ".solution-details__video-slider .js-slider-counter-all"
        );
        counterAll.textContent =
            slides.length > 10 ? `${slides.length}` : `0${slides.length}`;

        const videoSliderCounter = () => {
            const currentSlide = document.querySelector(
                ".js-solutions-video-slider .slick-current"
            );
            const currentSlideId = currentSlide.dataset.id;
            counterCurrent.textContent =
                currentSlideId > 10
                    ? `${currentSlideId}`
                    : `0${currentSlideId}`;
        };

        videoSlider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            appendArrows: $(".js-solutions-video-slider-buttons"),
            prevArrow: prevButtongray,
            nextArrow: nextButtongray,
            asNavFor: isMobile(),
        });

        if (document.body.clientWidth > 768) {
            $(".js-solutions-video-slider-preview").slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                asNavFor: ".js-solutions-video-slider",
            });
        }

        videoSlider.on("afterChange", videoSliderCounter);
    }

    //------------------ pictures-slider solution-details-page------------------
    const picturesSlider = $(".js-solutions-pictures-slider");

    if (picturesSlider[0]) {
        const isMobile = () => {
            if (document.body.clientWidth > 768) {
                return ".js-solutions-pictures-slider-preview";
            }
            return false;
        };

        const slides = document.querySelectorAll(
            ".js-solutions-pictures-slider .slider__slide"
        );

        slides.forEach((slide, i) => {
            slide.setAttribute("data-id", i + 1);
        });

        const counterCurrent = document.querySelector(
            ".solution-details__pictures-slider .js-slider-counter-current"
        );
        const counterAll = document.querySelector(
            ".solution-details__pictures-slider .js-slider-counter-all"
        );
        counterAll.textContent =
            slides.length > 10 ? `${slides.length}` : `0${slides.length}`;

        const picturesSliderCounter = () => {
            const currentSlide = document.querySelector(
                ".js-solutions-pictures-slider .slick-current"
            );
            const currentSlideId = currentSlide.dataset.id;
            counterCurrent.textContent =
                currentSlideId > 10
                    ? `${currentSlideId}`
                    : `0${currentSlideId}`;
        };

        picturesSlider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            appendArrows: $(".js-solutions-pictures-slider-buttons"),
            prevArrow: prevButtongray,
            nextArrow: nextButtongray,
            asNavFor: isMobile(),
        });

        if (document.body.clientWidth > 768) {
            $(".js-solutions-pictures-slider-preview").slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                asNavFor: ".js-solutions-pictures-slider",
            });
        }

        picturesSlider.on("afterChange", picturesSliderCounter);
    }

    //------------------ read-else slider------------------
    $(".js-read-else-slider").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        appendArrows: $(".js-read-else-slider-buttons"),
        prevArrow: prevButtongray,
        nextArrow: nextButtongray,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });

    //------------------ employee-reviews-slider------------------

    // desktop-slider

    if (document.body.clientWidth > 1024) {
        $(".js-employee-rate-slider-desktop").slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            appendArrows: $(".js-employee-rate-slider-buttons"),
            prevArrow: prevButtongray,
            nextArrow: nextButtongray,
        });
    }

    // mobile-slider
    if (document.body.clientWidth < 1025) {
        $(".js-employee-rate-slider-mobile ").slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            appendArrows: $(".js-employee-rate-slider-buttons"),
            prevArrow: prevButtongray,
            nextArrow: nextButtongray,
            responsive: [
                {
                    breakpoint: 800,
                    appendArrows: false,
                    settings: "unslick",
                },
            ],
        });
    }

    //------------------- our-work-slider --------------------
    const workSlider = $(".js-our-work-slider");

    let videoSliderCounter = null;

    if (workSlider[0]) {
        const slides = document.querySelectorAll(
            ".js-our-work-slider .slider__slide"
        );

        if (document.body.clientWidth < 768) {
            slides.forEach((slide, i) => {
                slide.setAttribute("data-id", i + 1);
            });

            const counterCurrent = document.querySelector(
                ".career-page__our-work-slider .js-slider-counter-current"
            );
            const counterAll = document.querySelector(
                ".career-page__our-work-slider .js-slider-counter-all"
            );
            counterAll.textContent =
                slides.length > 10 ? `${slides.length}` : `0${slides.length}`;

            videoSliderCounter = () => {
                const currentSlide = document.querySelector(
                    ".career-page__our-work-slider .slick-current"
                );
                const currentSlideId = currentSlide.dataset.id;
                counterCurrent.textContent =
                    currentSlideId > 10
                        ? `${currentSlideId}`
                        : `0${currentSlideId}`;
            };
        }

        firstSlideIsTitle = () => {
            const currentSlide = document.querySelector(
                ".career-page__our-work-slider .slick-current"
            );
            var dots = $(".slick-dots li");
            dots.each(function (k, v) {
                $(this).find("button").html(k);
                if (k === 0) $(v).find("button").html("-");
            });
        };

        workSlider.on("init", firstSlideIsTitle);

        workSlider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: true,
                        appendArrows: $(".js-our-work-slider-buttons"),
                        prevArrow: prevButtongray,
                        nextArrow: nextButtongray,
                        dots: false,
                    },
                },
            ],
        });

        if (document.body.clientWidth < 768) {
            workSlider.on("afterChange", videoSliderCounter);
        }
    }

    //------------------ employee-review-slider ------------------
    $(".js-employee-review-slider").slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        appendArrows: $(".js-employee-review-slider-buttons"),
        prevArrow: prevButtongray,
        nextArrow: nextButtongray,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });

    //------------------ our-nubmer-slider------------------

    // desktop-slider

    if (document.body.clientWidth > 1024) {
        $(".js-our-numbers-slider-desktop").slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            appendArrows: $(".js-our-numbers-slider-buttons"),
            prevArrow: prevButtongray,
            nextArrow: nextButtongray,
        });
    }

    // mobile-slider
    if (document.body.clientWidth < 1025) {
        $(".js-our-numbers-slider-mobile").slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            appendArrows: $(".js-our-numbers-slider-buttons"),
            prevArrow: prevButtongray,
            nextArrow: nextButtongray,
        });
    }

    if (document.body.clientWidth < 768) {
        $(".js-trends-mobile-slider").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            appendArrows: $(".js-trends-mobile-slider-buttons"),
            prevArrow: prevButtongray,
            nextArrow: nextButtongray,
        });
    }
});

if (window.innerWidth < 1199) {
    new Swiper(".main-experience__slider", {
        spaceBetween: 16,
        slidesPerView: "auto",
        navigation: {
            nextEl: ".main-experience__button-next",
            prevEl: ".main-experience__button-prev",
        },
        breakpoints: {
            320: {
                spaceBetween: 16,
            },
            1199: {
                spaceBetween: 0,
            },
        },
    });
}

new Swiper(".recommend-letters-slider__slider", {
    spaceBetween: 24,
    slidesPerView: "auto",
    navigation: {
        nextEl: ".main-recommend-letters__button-next",
        prevEl: ".main-recommend-letters__button-prev",
    },
    breakpoints: {
        320: {
            spaceBetween: 16,
        },
        1024: {
            spaceBetween: 24,
        },
    },
});
if (window.innerWidth > 768) {
    new Swiper(".main-expertise__cards", {
        spaceBetween: 24,
        slidesPerView: "auto",
        navigation: {
            nextEl: ".main-expertise__next",
            prevEl: ".main-expertise__prev",
        },
    });
}
// if( window.innerWidth > 768 ){
// 	new Swiper('.main-competencies__cards', {
// 		slidesPerView: 'auto',
// 		navigation: {
// 		nextEl: '.main-competencies__next',
// 		prevEl: '.main-competencies__prev',
// 		},

// 	});
// }

new Swiper(".slider-page__wrapper", {
    slidesPerView: 1,
    navigation: {
        nextEl: ".slider-page__next",
        prevEl: ".slider-page__prev",
    },
});

new Swiper(".tipical-page__cards-gray-one", {
    spaceBetween: 16,
    slidesPerView: "auto",

    navigation: {
        nextEl: ".tipical-page__gray-next-one",
        prevEl: ".tipical-page__gray-prev-one",
    },

    breakpoints: {
        1025: {
            spaceBetween: 0,
            allowSlidePrev: false,
            allowSlideNext: false,
            slidesPerView: "auto",
        },
    },
});
new Swiper(".tipical-page__cards-gray-two", {
    spaceBetween: 24,
    slidesPerView: "auto",

    navigation: {
        nextEl: ".tipical-page__gray-next-two",
        prevEl: ".tipical-page__gray-prev-two",
    },
});
new Swiper(".tipical-page__cards-orange", {
    spaceBetween: 24,
    slidesPerView: "auto",

    navigation: {
        nextEl: ".tipical-page__orange-next",
        prevEl: ".tipical-page__orange-prev",
    },
    breakpoints: {
        320: {
            spaceBetween: 16,
        },
        1024: {
            spaceBetween: 24,
        },
        1025: {
            allowSlidePrev: false,
            allowSlideNext: false,
            spaceBetween: 0,
            slidesPerView: "auto",
        },
    },
});
new Swiper(".tipical-page__cards-small", {
    spaceBetween: 16,
    slidesPerView: 1,

    navigation: {
        nextEl: ".tipical-page__small-next",
        prevEl: ".tipical-page__small-prev",
    },

    breakpoints: {
        1025: {
            allowSlidePrev: false,
            allowSlideNext: false,
            spaceBetween: 0,
            slidesPerView: "auto",
        },
    },
});

new Swiper(".tipical-page-moscow__cards-consulting", {
    slidesPerView: "auto",

    navigation: {
        nextEl: ".tipical-page__consulting-next",
        prevEl: ".tipical-page__consulting-prev",
    },
});
new Swiper(".detail-orange__block-first", {
    slidesPerView: "auto",
    spaceBetween: 24,
    navigation: {
        nextEl: ".detail-orange__block-first-next",
        prevEl: ".detail-orange__block-first-prev",
    },
});
new Swiper(".detail-orange__block-two", {
    slidesPerView: "auto",
    spaceBetween: 24,
    navigation: {
        nextEl: ".detail-orange__block-two-next",
        prevEl: ".detail-orange__block-two-prev",
    },
});
new Swiper(".tipical-page__usually-cards-slider", {
    slidesPerView: "auto",
    spaceBetween: 24,
    navigation: {
        nextEl: ".tipical-page__usually-next",
        prevEl: ".tipical-page__usually-prev",
    },
});

new Swiper(".cases-new__slider", {
    slidesPerView: "auto",
    spaceBetween: 24,
    navigation: {
        nextEl: ".cases-new__next",
        prevEl: ".cases-new__prev",
    },
});
if (window.innerWidth < 1366) {
    new Swiper(".tipical-page-moscow__slider", {
        slidesPerView: "auto",
        navigation: {
            nextEl: ".tipical-page__consulting-next",
            prevEl: ".tipical-page__consulting-prev",
        },
    });
}
new Swiper(".js-pages-list-swiper", {
    slidesPerView: "auto",
    spaceBetween: 16,
    breakpoints: {
        1025: {
            allowSlidePrev: false,
            allowSlideNext: false,
        },
    },
});

const showArrows = (arrows, count, slide, size) => {
    const arrow = document.querySelector(arrows);
    const slides = document.querySelectorAll(slide).length;
    if (window.innerWidth > size) {
        if (slides < count) {
            arrow.style.display = "none";
        } else {
            arrow.style.display = "flex";
        }
    }
};

const showArrowsDetailOne = () => {
    const wrapper = document.querySelector(".detail-section-one");
    const arrows = wrapper.querySelector(".detail-orange__card-btns");
    const slides = wrapper.querySelectorAll(".detail-orange__card").length;
    if (window.innerWidth > 1366) {
        if (slides < 4) {
            arrows.style.display = "none";
        } else {
            arrows.style.display = "flex";
        }
    }
};

const showArrowsDetail = () => {
    const wrapper = document.querySelector(".detail-section-two");
    const arrows = wrapper.querySelector(".detail-orange__card-btns");
    const slides = wrapper.querySelectorAll(".detail-orange__card").length;
    if (window.innerWidth > 1366) {
        if (slides < 4) {
            arrows.style.display = "none";
        } else {
            arrows.style.display = "flex";
        }
    }
};

const showArrowsGrayOne = () => {
    const wrapper = document.querySelector(".section-gray-one");
    const arrows = wrapper.querySelector(".tipical-page__gray-btns");
    const slides = wrapper.querySelectorAll(".cards-gray__card").length;
    if (window.innerWidth > 1366) {
        if (slides < 4) {
            arrows.style.display = "none";
        } else {
            arrows.style.display = "flex";
        }
    }
};
const showArrowsGray = () => {
    const wrapper = document.querySelector(".section-gray-two");
    const arrows = wrapper.querySelector(".tipical-page__gray-btns");
    const slides = wrapper.querySelectorAll(".cards-gray__card").length;
    if (window.innerWidth > 1366) {
        if (slides < 4) {
            arrows.style.display = "none";
        } else {
            arrows.style.display = "flex";
        }
    }
};

if (document.querySelector(".tipical-page__cards-gray"))
    showArrows(".tipical-page__gray-btns", 4, ".cards-gray__card", 1366);

if (document.querySelector(".tipical-page__cards-orange"))
    showArrows(
        ".tipical-page__orange-btns",
        5,
        ".tipical-page__card-orange",
        1366
    );
if (document.querySelector(".tipical-page__card-small"))
    showArrows(
        ".tipical-page__small-btns",
        6,
        ".tipical-page__card-small",
        1366
    );

if (document.querySelector(".detail-section-two")) showArrowsDetail();
if (document.querySelector(".detail-section-one")) showArrowsDetailOne();

if (document.querySelector(".section-gray-one")) showArrowsGrayOne();
if (document.querySelector(".section-gray-two")) showArrowsGray();

new Swiper(".about-statistics__slider", {
    slidesPerView: 4,
    navigation: {
        nextEl: ".about-new-next",
        prevEl: ".about-new-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: "auto",
        },

        1024: {
            slidesPerView: 4,
        },
    },
});
