const careerBlock = document.querySelectorAll('.js-career-show-more');

if (document.body.clientWidth < 768 && careerBlock[0]) {
    careerBlock.forEach(careerItem => {
        const showMoreButton = careerItem.querySelector('.js-career-show-more-btn');
        showMoreButton.addEventListener('click', () => {
            const hiddenBlocks = careerItem.querySelectorAll('.quiz-list__item.mobile-hide');
            hiddenBlocks.forEach(block => {
                block.classList.remove('mobile-hide');
            });
            showMoreButton.remove();
        })
    });
}
