const burger = document.querySelector('.js-burger');

if (burger) {
    const mobileMenu = document.querySelector('.header-nav');
    const header = document.querySelector('.site-header');
    const body = document.querySelector('body');
    burger.addEventListener('click', () => {
        burger.classList.toggle('burger--active');
        body.classList.toggle('body-noscroll');
        body.classList.toggle('open');
        header.classList.toggle('site-header--white');
        mobileMenu.classList.toggle('header-nav--active');
    })
}
