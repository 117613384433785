$(() => {
    svg4everybody();
});

if(document.querySelector('.suppot-page__more')){
    document.querySelector('.suppot-page__more').addEventListener('click', () => {
        document.querySelector('.mobile-bg').classList.remove('show')
        document.querySelectorAll('.mobile-hide').forEach(el => {
            el.classList.add('show')
            document.querySelector('.suppot-page__more').style.display = 'none'
        })
    })
}
if( document.querySelector('.staff__faces-new') &&  document.querySelector('.staff__faces-link-new')){
    const staffBlock =  document.querySelector('.staff__faces-new')
    const staffBtn = document.querySelector('.staff__faces-link-new')
    staffBtn.addEventListener('click', () => {
        staffBlock.classList.toggle('active')
        if(staffBlock.classList.contains('active')){
            staffBtn.textContent = 'Скрыть'
        } else{
            staffBtn.textContent = 'Подробнее +'
        }
    })
}


if(document.querySelector('.tipical-page__accardion')){
    document.querySelectorAll('.tipical-page__accardion-row').forEach(btn => {
    btn.addEventListener('click',() => {
        const text = btn.nextElementSibling
        text.classList.toggle('active')
        btn.querySelector('button').classList.toggle('active')
    })
})
}

const showAllMobile = () => {
    const showMoreButton = document.querySelector('.js-quiz-load-more');
    if (document.body.clientWidth < 768 && showMoreButton) {
        showMoreButton.addEventListener('click', () => {
            const hiddenOffers = document.querySelectorAll('.quiz-list__item.mobile-hide');
            hiddenOffers.forEach(offer => {
                offer.classList.remove('mobile-hide');
            })
            showMoreButton.style.display = "none";
        })
    }
}

showAllMobile();
