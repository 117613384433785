

class Polls {
    constructor() {
        this.form = document.querySelector('.js-polls-wrapper');
        this.init();
    }

    init() {
        if (!this.form) return;
        const _this = this;
        this.form.addEventListener('submit', function(event) {
            event.preventDefault();
            console.log('event', event);
            const formData = new FormData(event.target);

            fetch('/local/ajax.php', {
                method: 'POST',
                body: formData
            })
                .then(async response => {
                    if (response.ok) {
                        const responseData = await response.json();
                        if (responseData.status === 'fail') throw new Error('Ошибка при отправке данных');
                        const btn = document.querySelector('.js-fake-hidden-polls-btn');
                        btn.click();
                        setTimeout(() => {
                            window.location.replace("/");
                        }, 3000);
                        _this.form.style.visibility = "hidden";
                        console.log('Данные успешно отправлены');
                    } else {
                        console.error('Произошла ошибка при отправке данных');
                    }
                })
                .catch(error => {
                    console.error('Произошла ошибка:', error);
                });
        });
    }
}

new Polls();
