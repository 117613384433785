const closeFilterModal = () => {
    body.style.overflow = null;
    body.style['padding-right'] = null;
};

$(document).on('change', '.js-filter__solutions .checkbox__input', function (e) {
    var oldLink = $(this).data('href');
    var link = '';
    if (oldLink.indexOf('?') !== -1)
        link = oldLink + '&solutions=';
    else
        link = oldLink + '?solutions=';

    $('.js-filter__solutions input:checkbox:checked').each(function() {
        link += $(this).val();
    });
    history.pushState(null, null, link);
    $.ajax({
        url: link,
        success: function (response) {
            var result = $($(response).find('.ajax-container')).html();
            $('.ajax-container').html(result);
            closeFilterModal();
            modalInit(true);
        }
    });
});

$(document).on('change', '.js-filter__industries .checkbox__input', function (e) {
    var oldLink = $(this).data('href');
    var link = '';
    if (oldLink.indexOf('?') !== -1)
        link = oldLink + '&industries=';
    else
        link = oldLink + '?industries=';

    $('.js-filter__industries input:checkbox:checked').each(function() {
        link += $(this).val();
    });
    history.pushState(null, null, link);
    $.ajax({
        url: link,
        success: function (response) {
            var result = $($(response).find('.ajax-container')).html();
            $('.ajax-container').html(result);
            closeFilterModal();
            modalInit(true);
        }
    });
});
