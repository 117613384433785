const toggleButtons = document.querySelectorAll('.js-toggle-lang');

toggleButtons[0] && toggleButtons.forEach(button => {
    const closedropDown = () => {
        button.classList.remove('site-header__toogle-lang--active');
        window.removeEventListener('click', handleClick);
        window.removeEventListener('keydown', handleEscPress);
    }

    const handleClick = (evt) => {
        if (!evt.target.closest('.site-header__toogle-dropdown')) {
            closedropDown();
        }
    }

    const handleEscPress = (evt) => {
        if (evt.key === 'Escape') {
            closedropDown();
        }
    }

    const openDropDown = (evt) => {
        evt.preventDefault();
        if (!button.classList.contains('site-header__toogle-lang--active')) {
            button.classList.add('site-header__toogle-lang--active');

            setTimeout(() => {
                window.addEventListener('click', handleClick);
                window.addEventListener('keydown', handleEscPress);
            }, 10);
        }
    }

    button.addEventListener('click', openDropDown);
})
