const headerNav = document.querySelectorAll('.js-header-nav-item');

headerNav[0] && headerNav.forEach(nav => {
    const dropDownButton = nav.querySelector('.header-nav__link');
    const dropdownMenu = nav.querySelector('.dropdown-menu');
    const hiddenBlock = nav.querySelector('.header-nav__hidden');

    const handleClick = (evt) => {
        if (!evt.target.closest('.dropdown-menu')) {
            closeMenu();
        }
    }

    const handleEscPress = (evt) => {
        if (evt.key === 'Escape') {
            closeMenu();
        }
    }

    const closeMenu = () => {
        nav.classList.remove('header-nav__item--active');
        window.removeEventListener('click', handleClick);
        window.removeEventListener('keydown', handleEscPress);

        if (document.body.clientWidth < 1367) {
            hiddenBlock.style.maxHeight = '0';
        }
    }

    const openMenu = (evt) => {
        evt.preventDefault();
        if (!nav.classList.contains('header-nav__item--active')) {
            nav.classList.add('header-nav__item--active');

            if (document.body.clientWidth < 1367) {
                const dropdownMenuHeight = dropdownMenu.offsetHeight;
                hiddenBlock.style.maxHeight = dropdownMenuHeight + 'px';
            }

            setTimeout(() => {
                window.addEventListener('click', handleClick);
                window.addEventListener('keydown', handleEscPress);
            }, 10);
        }
    }

    dropDownButton.addEventListener('click', openMenu);
});
