const body = document.querySelector('body');
const modalButtons = document.querySelectorAll('.js-modal-button');
const modals = Array.from(document.querySelectorAll('.js-modal'));
const iframes = document.querySelectorAll('iframe');

const modalInit = (reinit) => {
    if (modalButtons[0] && modals[0]) {
        const initModals = (openModalbutton) => {
            const modalName = openModalbutton.getAttribute('data-modal');
            const modalTitle = openModalbutton.getAttribute('data-title') ?? '';
            const isModalCulture = openModalbutton.getAttribute('data-culture') ?? 'false';
            let modal = modals.find((modal) => modal.getAttribute('data-modal') === modalName);
            let newText = '';
            if (modalName === 'modal-advantage') {
                if (isModalCulture === 'true') {
                    newText = $(openModalbutton).find('.js-previews__modal-text').html() ?? '';
                } else {
                    newText = $(openModalbutton).parent().find('.js-previews__modal-text').html() ?? '';
                }
                $(modal).find('.js-modal__scroll-container').html(newText);
                $(modal).find('.js-modal-title').text(modalTitle);
            } else if (modalName === 'modal-image') {
                let imageSrc = $(openModalbutton).attr('data-src') ?? '';
                $(modal).find('.js-modal-image-element').attr('src', imageSrc);
            } else if (modalName === 'filter') {
                const modals = Array.from(document.querySelectorAll('.js-modal'));
                modal = modals.find((modal) =>{
                    if (modal.getAttribute('data-modal') === modalName) {
                        return modal.getAttribute('data-modal') === modalName;
                    }
                });
            }
            const closeButton = modal.querySelector('.js-modal-close');
            let scrollbarWidth = (window.innerWidth - document.body.clientWidth) + 'px';

            const closeModal = () => {
                iframes.forEach(iframe => { 
                    iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
                });
                body.style.overflow = null;
                body.style['padding-right'] = null;
                modal.classList.remove('modal--active');
                closeButton.removeEventListener('click', closeModal);
                window.removeEventListener('keydown', handleEscPress);
                modal.removeEventListener('click', handleOverlayClick);

                const modalScroll = document.getElementsByClassName('modal__text-wrap');

                for (let i=0, length = modalScroll.length; i < length; i++) {
                    modalScroll[i].scrollTop = (0, 0);
                }
            };

            const openModal = (modal) => {
                body.style.overflow = 'hidden';
                body.style['padding-right'] = scrollbarWidth;
                modal.classList.add('modal--active');
                closeButton.addEventListener('click', closeModal);
                window.addEventListener('keydown', handleEscPress);
                modal.addEventListener('click', handleOverlayClick);
                const modalScroll = document.getElementsByClassName('modal__text-wrap');

                for (let i=0, length = modalScroll.length; i < length; i++) {
                    modalScroll[i].scrollTop = (0, 0);
                }
            };

            const handleEscPress = (evt) => {
                if (evt.key === 'Escape') {
                    closeModal();
                }
            };

            const handleOverlayClick = (evt) => {
                if (evt.target.classList.contains('modal')) {
                    closeModal();
                }
            };

            openModal(modal);
        };

        modalButtons.forEach(button => {
            if (button.dataset.isModalInited !== 'true') {
                button.dataset.isModalInited = 'true';
                button.addEventListener('click', () => {
                    initModals(button);
                });
            }
        });

        if (reinit) {
            const modalButtonsReinit = document.querySelectorAll('.js-modal-button');
            modalButtonsReinit.forEach(button => {
                if (button.dataset.isModalInited !== 'true') {
                    button.dataset.isModalInited = 'true';
                    button.addEventListener('click', () => {
                        initModals(button);
                    });
                }
            });
        }
    };
}

modalInit(false);
