const initMap = () => {
	const placeMarkcoords = [
		[58.82669562046019, 86.34001538704685],
		[46.82050412122694, 8.413651010814288],
		[23.700154295903364, 53.98555126016649],
	];

	const init = () => {
		let map = null;

		map = new ymaps.Map("contact-map", {
			center: [53.654420703472724,45.54803746915954],
			zoom: 3,
			controls: ['zoomControl'],
		});

		map.behaviors.disable('scrollZoom');

		// if (document.body.clientWidth < 1368) {
		// 	map = new ymaps.Map("contact-map", {
		// 		center: [53.654420703472724,45.54803746915954],
		// 		zoom: 2,
		// 		controls: ['zoomControl'],
		// 	});
		// } else if (document.body.clientWidth > 1920) {
        //     map = new ymaps.Map("contact-map", {
		// 		center: [53.654420703472724,45.54803746915954],
		// 		zoom: 3,
		// 		controls: ['zoomControl'],
		// 	});
        // } else {
		// 	map = new ymaps.Map("contact-map", {
		// 		center: [53.654420703472724,45.54803746915954],
		// 		zoom: 3,
		// 		controls: ['zoomControl'],
		// 	});
		// };

		const getGeoTemplate = (coords) => {
			return new ymaps.Placemark(coords, {}, {
				iconLayout: 'default#image',
				iconImageHref: '/local/templates/teamidea2022/markup/build/assets/img/pin.svg',
				iconImageSize: [34, 46],
				iconImageOffset: [-17, -46],
			})
		};

		placeMarkcoords.forEach(coord => {
			map.geoObjects.add(getGeoTemplate(coord));
		});

		map.geoObjects.events.add('click', (e) => {
			let pinCoord = e.get('target').geometry.getCoordinates();
			map.panTo(pinCoord)
				.then(() => map.setZoom(4, {
					duration: 500
				}))
		});
	}

	ymaps.ready(init);
}


// const initMap = () => {
// 	const icon = L.icon({
// 		iconUrl: '/assets/img/pin.svg',
// 		iconSize: [27, 39],
// 		iconAnchor: [20, 40],
// 		bubblingMouseEvents: true
// 	});

// 	const placeMarkcoords = [
// 		[58.82669562046019, 86.34001538704685],
// 		[46.82050412122694, 8.413651010814288],
// 		[38.53334909376648, -99.2910311914274]
// 	];

// 	const panToMarker = (evt) => {
// 		map.flyTo([evt.latlng.lat, evt.latlng.lng],
// 			4,
// 			{
// 			duration: 0.5
// 		})

// 	}

// 	const map = L.map('contact-map').setView([51.505, -0.09], 13);

// 	if (document.body.clientWidth < 1250) {
// 		map.setZoom(1);
// 		map.setView([25, 0]);
// 		console.log('< 1250')
// 	} else if (document.body.clientWidth < 1368) {
// 		map.setZoom(2);
// 		map.setView([45, 0]);
// 		console.log('< 1368')
// 	} else if (document.body.clientWidth > 1920) {
// 		map.setZoom(2);
// 		map.setView([30, 0]);
// 		console.log('> 1920')
// 	} else {
// 		map.setZoom(2);
// 		map.setView([35, 0]);
// 		console.log('desctop')
// 	}

// 	L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
//         maxZoom: 19,
//         attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
//       }).addTo(map);
// 	placeMarkcoords.forEach(coords => {
// 		L.marker(coords, {icon: icon}).addTo(map).addEventListener('click', panToMarker);
// 	});
// }

const mainMap = document.querySelector('.js-main-map-init-scroll');

const initMapByScroll = () => {
	if ((mainMap.getBoundingClientRect().top - document.documentElement.clientHeight < 0) && document.body.clientWidth > 400) {
        document.removeEventListener('scroll', initMapByScroll);
		initMap();
	}
}

mainMap && document.addEventListener('scroll', initMapByScroll);
